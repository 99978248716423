import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const AuthenticationStoreModel = types
  .model('AuthenticationStore')
  .props({
    authCustomerNumbers: types.maybe(types.string),
    authEmail: types.maybe(types.string),
    authId: types.maybe(types.string),
    authName: types.maybe(types.string),
    authSelectedCustomerNumber: types.maybe(types.string),
    authToken: types.maybe(types.string),
    authenticated: false,
  })
  .views(store => ({
    get isAuthenticated() {
      return !!store.authToken && store.authenticated;
    },
  }))
  .actions(store => ({
    async logout() {
      store.authCustomerNumbers = undefined;
      store.authSelectedCustomerNumber = undefined;
      store.authEmail = undefined;
      store.authName = undefined;
      store.authToken = undefined;
      store.authenticated = false;
      store.authId = undefined;
    },
    setAuthCustomerNumbers(value: string) {
      store.authCustomerNumbers = value;
      store.authSelectedCustomerNumber = JSON.stringify(JSON.parse(value)[0] ?? '');
    },
    setAuthEmail(value: string) {
      store.authEmail = value;
    },
    setAuthId(value: string) {
      store.authId = value;
    },
    setAuthName(value: string) {
      store.authName = value;
    },
    setAuthToken(value?: string) {
      store.authToken = value;
    },
    setAuthenticated(value: boolean) {
      store.authenticated = value;
    },
    setSelectedCustomerNumber(value: string) {
      store.authSelectedCustomerNumber = value;
    },
  }));

export type AuthenticationStore = Instance<typeof AuthenticationStoreModel>;
export type AuthenticationStoreSnapshot = SnapshotOut<typeof AuthenticationStoreModel>;
