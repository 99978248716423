import { z } from 'zod';
import { ChargePointV2 } from './chargePointV2';

export const ChargeStationV2 = z.object({
  chargePoints: z.array(ChargePointV2),
  id: z.string(),
  isOpen24Hours: z.boolean(),
  location: z.object({
    city: z.string(),
    country: z.object({
      code: z.string(),
      name: z.string(),
    }),
    entrance: z.nullable(z.object({ latitude: z.nullable(z.number()), longitude: z.nullable(z.number()) })),
    floor: z.nullable(z.string()),
    houseNumber: z.nullable(z.string()),
    latitude: z.number(),
    longitude: z.number(),
    region: z.nullable(z.string()),
    street: z.string(),
    zipcode: z.nullable(z.string()),
  }),
  openingTimes: z.nullable(
    z.object({
      periods: z.array(
        z.object({
          begin: z.string(),
          end: z.string(),
        })
      ),
      when: z.string(),
    })
  ),
  operator: z.object({ name: z.string(), token: z.string() }),
  stationId: z.string().optional(),
  stationNameOriginal: z.string(),
});
export type ChargeStationV2 = z.infer<typeof ChargeStationV2>;
