import { TenantQueueLoadFrequency } from '@prisma/client';

export enum IdentificationType {
  RFID = 'RFID',
  REMOTE = 'REMOTE',
  QR_CODE = 'QR_CODE',
}

export enum CostComponentType {
  ENERGY = 'ENERGY',
  MANUAL = 'MANUAL',
  PARKING = 'PARKING',
  SERVICE = 'SERVICE',
  START = 'START',
  TIME = 'TIME',
}

export enum ExtendWithOption {
  WITH_CLEARING = 'WITH_CLEARING',
  WITH_CLEARING_AND_COST_COMPONENTS = 'WITH_CLEARING_AND_COST_COMPONENTS',
}

export enum PlugEnum {
  AVCON_CONNECTOR = 'AVCON_CONNECTOR',
  CCS_COMBO_1_PLUG_WITH_CABLE = 'CCS_COMBO_1_PLUG_WITH_CABLE',
  CCS_COMBO_2_PLUG_WITH_CABLE = 'CCS_COMBO_2_PLUG_WITH_CABLE',
  CHADEMO = 'CHADEMO',
  IEC_60309_SINGLE_PHASE = 'IEC_60309_SINGLE_PHASE',
  IEC_60309_THREE_PHASE = 'IEC_60309_THREE_PHASE',
  LARGE_PADDLE_INDUCTIVE = 'LARGE_PADDLE_INDUCTIVE',
  NEMA_5_20 = 'NEMA_5_20',
  SMALL_PADDLE_INDUCTIVE = 'SMALL_PADDLE_INDUCTIVE',
  TESLA_CONNECTOR = 'TESLA_CONNECTOR',
  TYPE_1_CONNECTOR_WITH_CABLE = 'TYPE_1_CONNECTOR_WITH_CABLE',
  TYPE_2_CONNECTOR_WITH_CABLE = 'TYPE_2_CONNECTOR_WITH_CABLE',
  TYPE_2_OUTLET = 'TYPE_2_OUTLET',
  TYPE_3_OUTLET = 'TYPE_3_OUTLET',
  TYPE_E_FRENCH_STANDARD = 'TYPE_E_FRENCH_STANDARD',
  TYPE_F_SCHUKO = 'TYPE_F_SCHUKO',
  TYPE_G_BRITISH_STANDARD = 'TYPE_G_BRITISH_STANDARD',
  TYPE_J_SWISS_STANDARD = 'TYPE_J_SWISS_STANDARD',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum PowerType {
  AC = 'AC',
  DC = 'DC',
}

export enum ErrorCode {
  CHARGE_POINT_IS_RESERVED = 'CHARGE_POINT_IS_RESERVED',
  CHARGE_POINT_NOT_AVAILABLE = 'CHARGE_POINT_NOT_AVAILABLE',
  CHARGE_POINT_NOT_REACHABLE = 'CHARGE_POINT_NOT_REACHABLE',
  CHARGE_POINT_REJECTED_CHARGING = 'CHARGE_POINT_REJECTED_CHARGING',
  CHARGING_NOT_PERMITTED = 'CHARGING_NOT_PERMITTED',
  COMMUNICATION_TO_ROAMING_SYSTEM_FAILED = 'COMMUNICATION_TO_ROAMING_SYSTEM_FAILED',
  COMMUNICATION_TO_ROAMING_SYSTEM_TIMEOUT = 'COMMUNICATION_TO_ROAMING_SYSTEM_TIMEOUT',
  GENERIC_ERROR = 'GENERIC_ERROR',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  NO_VALID_DRIVER_IDENTIFICATION = 'NO_VALID_DRIVER_IDENTIFICATION',
  NO_VALID_ROAMING_CONTRACT = 'NO_VALID_ROAMING_CONTRACT',
  NO_VEHICLE_CONNECTED_TO_CHARGE_POINT = 'NO_VEHICLE_CONNECTED_TO_CHARGE_POINT',
  OTHER_SESSION_ACTIVE = 'OTHER_SESSION_ACTIVE',
  PRICING_VALIDITY_TOKEN_INVALID = 'PRICING_VALIDITY_TOKEN_INVALID',
}

export enum UnitType {
  START = 'START',
  TIME = 'TIME',
  ENERGY = 'ENERGY',
  MINCAP = 'MINCAP',
  MAXCAP = 'MAXCAP',
  MANUAL = 'MANUAL',
}

export enum Currency {
  EUR = 'EUR',
  CHF = 'CHF',
}

export enum CommissionContractType {
  COMMISSION = 'COMMISSION',
  REFUND = 'REFUND',
}

export interface DefaultLoadOptions {
  fullLoadFrequency?: TenantQueueLoadFrequency;
}
