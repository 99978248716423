import { z } from 'zod';
import { evseIdSchemaProp } from './general-validators';
import { PlugEnum } from './enums/tmp.enums';

export enum ChargePointStatusEnum {
  AVAILABLE = 'AVAILABLE',
  OCCUPIED = 'OCCUPIED',
  RESERVED = 'RESERVED',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  UNKNOWN = 'UNKNOWN',
}
const ChargePointStatusEnumConst = z.nativeEnum(ChargePointStatusEnum);
export type ChargePointStatus = z.infer<typeof ChargePointStatusEnumConst>;

const PlugEnumConst = z.nativeEnum(PlugEnum);
export type Plug = z.infer<typeof PlugEnumConst>;

export type PlugIconName =
  | 'alert-circle-outline'
  | 'ev-plug-ccs2'
  | 'ev-plug-chademo'
  | 'ev-plug-type1'
  | 'ev-plug-type2'
  | 'power-socket-eu';

const chargingSessionSchema = z.object({
  consumedEnergy: z.number(),
  emobilityServiceProviderIdentifier: z.string(),
  emobilityServiceProviderName: z.string(),
  sessionEnd: z.string().datetime(),
  sessionId: z.string(),
  sessionStart: z.string().datetime(),
  startedVia: z.string(),
  status: z.string(),
  stoppedReason: z.string(),
});
// type ChargingSession = z.infer<typeof chargingSessionSchema>;

const chargePointLocationSchema = z.object({
  city: z.string(),
  country: z.object({
    code: z.string(),
    name: z.string(),
  }),
  entrance: z.string().nullish(),
  floor: z.string().nullish(),
  houseNumber: z.string().nullish(),
  latitude: z.number(),
  longitude: z.number(),
  region: z.string().nullish(),
  street: z.string(),
  zipcode: z.string().nullish(),
});

const chargePointSchema = z.object({
  authorizationGroups: z.array(z.string()),
  evseId: evseIdSchemaProp,
  hardwareHelpLink: z.string().nullish(),
  hardwareInOperationSince: z.string().datetime(),
  hardwareManufacturer: z.string(),
  hardwareModel: z.string(),
  isChargingAllowed: z.boolean(),
  isNationalCalibrationLawCompliant: z.boolean(),
  isPublic: z.boolean(),
  location: chargePointLocationSchema,
  maxPower: z.number(),
  operatorProduct: z.string().nullish(),
  plugs: z.array(z.nativeEnum(PlugEnum)),
  recentChargingSessions: z.array(chargingSessionSchema),
  serviceLevelAgreement: z.enum(['A', 'B', 'C', 'D', 'E']).nullish(),
  status: z.nativeEnum(ChargePointStatusEnum),
});
export type ChargePoint = z.infer<typeof chargePointSchema>;

export const ownedChargePointViewModelSchema = z.object({
  deliveredEnergyIsExportable: z.boolean(),
  evseId: evseIdSchemaProp,
});
export type OwnedChargePointViewModel = z.infer<typeof ownedChargePointViewModelSchema>;
