import { z } from 'zod';
import { evseIdSchemaProp } from './general-validators';

export enum ChargePointStatusV2 {
  AVAILABLE = 'AVAILABLE',
  OCCUPIED = 'OCCUPIED',
  RESERVED = 'RESERVED',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  UNKNOWN = 'UNKNOWN',
}
const ChargePointStatusV2Enum = z.nativeEnum(ChargePointStatusV2);
export type ChargePointStatusV2Enum = z.infer<typeof ChargePointStatusV2Enum>;

export enum PlugV2 {
  AVCON_CONNECTOR = 'AVCON_CONNECTOR',
  CCS_COMBO_1_PLUG_WITH_CABLE = 'CCS_COMBO_1_PLUG_WITH_CABLE',
  CCS_COMBO_2_PLUG_WITH_CABLE = 'CCS_COMBO_2_PLUG_WITH_CABLE',
  CHADEMO = 'CHADEMO',
  IEC_60309_SINGLE_PHASE = 'IEC_60309_SINGLE_PHASE',
  IEC_60309_THREE_PHASE = 'IEC_60309_THREE_PHASE',
  LARGE_PADDLE_INDUCTIVE = 'LARGE_PADDLE_INDUCTIVE',
  NEMA_5_20 = 'NEMA_5_20',
  SMALL_PADDLE_INDUCTIVE = 'SMALL_PADDLE_INDUCTIVE',
  TESLA_CONNECTOR = 'TESLA_CONNECTOR',
  TYPE_1_CONNECTOR_WITH_CABLE = 'TYPE_1_CONNECTOR_WITH_CABLE',
  TYPE_2_CONNECTOR_WITH_CABLE = 'TYPE_2_CONNECTOR_WITH_CABLE',
  TYPE_2_OUTLET = 'TYPE_2_OUTLET',
  TYPE_3_OUTLET = 'TYPE_3_OUTLET',
  TYPE_E_FRENCH_STANDARD = 'TYPE_E_FRENCH_STANDARD',
  TYPE_F_SCHUKO = 'TYPE_F_SCHUKO',
  TYPE_G_BRITISH_STANDARD = 'TYPE_G_BRITISH_STANDARD',
  TYPE_J_SWISS_STANDARD = 'TYPE_J_SWISS_STANDARD',
  UNSPECIFIED = 'UNSPECIFIED',
}
const PlugV2Enum = z.nativeEnum(PlugV2);
export type PlugV2Enum = z.infer<typeof PlugV2Enum>;

export const ChargePointContractV2 = z.object({
  contractNumber: z.nullable(z.string()),
  description: z.nullable(z.string()),
  externalId: z.string(),
});
export type ChargePointContractV2 = z.infer<typeof ChargePointContractV2>;

export const ChargePointV2 = z.object({
  authorizationGroups: z.array(z.string()),
  evseId: evseIdSchemaProp,
  infrastructureContract: z.nullable(ChargePointContractV2),
  isChargingAllowed: z.nullable(z.boolean()),
  isNationalCalibrationLawCompliant: z.boolean(),
  isPublic: z.boolean(),
  maxPower: z.number(),
  plugs: z.array(PlugV2Enum),
  status: ChargePointStatusV2Enum,
});
export type ChargePointV2 = z.infer<typeof ChargePointV2>;
